@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.details-session {
  border-top: 1px solid light.$ge-divider-default;
  padding: spacing.$s spacing.$s spacing.$s 0;
  margin-left: spacing.$s;

  &--past {
    color: light.$on-background-secondary-alternate;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__session-number {
    color: light.$on-background-secondary-disabled;
  }
}
